.loading-wrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-wrapper img {
  width: 50%;
  animation: light-up 2s infinite;
}

@keyframes light-up {
  0% {
    filter: brightness(1);
  }

  50% {
    filter: brightness(75%);
  }

  100% {
    filter: brightness(1);
  }
}
